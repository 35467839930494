export function ResourcesFormData(data = {}) {
    this.id = data.id || null;
    this.resourceName = data.resourceName || null;
    this.category = data.category || "";
    this.typeId = data.typeId || null;
    this.poster = data.poster || null;
    this.posters = (typeof data.posters == "string" ? data.posters.split(",") : data.posters) || [];
    this.filePath = data.filePath || null;
    this.fileName = data.fileName || null;
    this.resourceType = data.resourceType || null;
    this.tags = data.tags || null;
    this.description = data.description || null;
    this.richContent = data.richContent || null;
    this.type = data.type || 1; // 类型 1资源 2文章
    this.naturalWidth = data.naturalWidth || 0;
    this.naturalHeight = data.naturalHeight || 0;
}


ResourcesFormData.prototype = {
    build() {
        return {
            ...this
        }
    }
}

export function UserBasicFormData(data = {}) {
    this.id = data.id || null;
    this.nickName = data.nickName || null;
    this.avatarUrl = data.avatarUrl || null;
}

UserBasicFormData.prototype = {
    build() {
        return {
            ...this
        }
    }
}

// 密码修改表单
export function PasswordChangeFormData(data = {}) {
    this.oldPassword = data.oldPassword || "";
    this.newPassword = data.newPassword || "";
    this.repeatPassword = data.repeatPassword || "";
}

PasswordChangeFormData.prototype = {
    build() {
        return {
            ...this
        }
    }
}


// vip表单信息
export function VipFormData(data = {}) {
    this.id = data.id || null;
    this.nickName = data.nickName || null;
    this.avatarUrl = data.avatarUrl || null;
    this.type = data.type || 1;
    this.expirationTime = data.expirationTime || null;
    this.expireDay = data.expireDay || null;
}

VipFormData.prototype = {
    build() {
        return {
            ...this
        }
    }
}


export function CommentFormData(data = {}) {
    this.id = data.id || null
    this.comment = data.comment || ""
    this.rId = data.rId || null
    this.cId = data.cId || null
    this.toUserId = data.toUserId || null
}

CommentFormData.prototype = {
    build() {
        return {
            ...this
        }
    }
}