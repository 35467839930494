<template>
  <div class="detail">
    <div class="detail-body">
      <div class="basic">
        <div class="poster">
          <p class="poster-title">{{ detailData.resourceName }}</p>
          <div class="poster-preview">
            <el-image :src="item && $store.state.assetsUrl + item" alt="" v-for="(item, index) in detailData.posters"
              :key="index" :preview-src-list="detailData.posters.map((v) => $store.state.assetsUrl + v)
                " lazy></el-image>
          </div>
        </div>
        <div class="info">
          <div class="buttons">
            <el-button :disabled="isDisabled || detailData.downloading !== null" class="purchase" type="primary"
              @click="download">
              <p class="downloading-progress" v-show="detailData.downloading !== null">
                <el-progress :percentage="detailData.downloading || 0" :show-text="false" text-color="#fff"
                  define-back-color="transparent" :stroke-width="32"></el-progress>
              </p>
              <span>立即下载</span>
            </el-button>
            <el-button :disabled="isDisabled" class="collect" @click="collect">
              <img v-if="detailData.isCollect > 0" :src="CollectActive" alt="" />
              <img v-else :src="Collect" alt="" />
            </el-button>
          </div>
          <p class="info-category">
            <span class="info-label">类型：</span>{{ detailData.categoryName }}
          </p>
          <p class="info-category">
            <span class="info-label">风格：</span>{{ detailData.typeName }}
          </p>
          <p class="info-publish">
            <span class="info-label">文件格式：</span>{{ detailData.resourceTypeName }}
          </p>
          <p class="info-publish">
            <span class="info-label">上传日期：</span>{{ parseTime(detailData.createTime, "{y}/{m}/{d}") }}
          </p>
          <p class="info-tags">
            <span class="tag-item" v-for="(item, index) in detailData.tags &&
              detailData.tags.split(',')" :key="index">
              {{ item }}
            </span>
          </p>
          <div class="info-desc">摘要信息：
            <div class="info-desc__content" v-html="detailData.description"></div>
          </div>
          <p class="info-author">
            声明：系统资源不可私自转载，站内所有视频、音频、图片、文档、源码等项目信息仅供用户个人学习使用请各位用户勿用于商业用途，若您发现权利被侵害，请截图保留证据并直接发送邮件至864823609@qq.com。我们将第一时间处理，感谢您的支持！
          </p>
        </div>
      </div>
      <div class="rich" v-if="detailData.type == 2">
        <h2 class="rich-title">资源介绍</h2>
        <div class="rich-content" v-html="detailData.richContent"></div>
      </div>
    </div>
    <div class="message-body">
      <h2 class="message-title">留言面板</h2>
      <div class="message" :class="{ noLogin: !userInfo }">
        <div class="message-input" v-if="userInfo">
          <el-input ref="message" v-model="commentInfo.comment" type="textarea" placeholder="快写下您的想法" :rows="5"
            :maxlength="300"></el-input>
          <p class="message-btns">
            <span class="post-limit">{{ commentInfo.comment.length }}/300</span>
            <span class="post-btn" :class="{ disabled: commentInfo.comment.length == 0 }" type="primary"
              @click="postComment">发布</span>
          </p>
        </div>
        <div class="message-input" v-else>
          <span class="login-tips" @click="goLogin">登录</span>
          后可以发表自己的评论哦
        </div>
      </div>
      <h2 class="message-title title-for-comments">精选留言</h2>
      <div class="comments" v-if="detailData.comments.length > 0">
        <div class="comments-right">
          <div class="comments-item" v-for="(c, ci) in detailData.comments" :key="ci">
            <div class="comments-item-logo">
              <img :src="c.avatarUrl && $store.state.assetsUrl + c.avatarUrl" />
            </div>
            <div class="comments-item-info">
              <p class="comment-user">
                <span>{{ c.nickName }}</span>
              </p>
              <p class="comments-item-content">
                <span>{{ c.comment }}</span>
              </p>
              <p class="operate-btn">
                <span class="operate-time">
                  <i class="el-icon-time"></i>{{ parseTime(c.createTime) }}
                </span>
                <span class="operate-btn-back" @click="callback(c, ci, null, null)"><i
                    class="el-icon-chat-dot-round"></i>回复</span>
                <span class="operate-btn-delete" v-if="userInfo && c.uId == userInfo.id"
                  @click="deleteComment(c, ci, -1)"><i class="el-icon-delete"></i>删除</span>
              </p>
              <div class="message" v-show="c.showMessage" v-if="userInfo">
                <div class="message-input">
                  <el-input :ref="'message_' + ci" v-model="c.commentMessage" type="textarea" placeholder="评论" :rows="5"
                    :maxlength="300"></el-input>
                  <p class="message-btns">
                    <span class="post-limit">{{
                      (c.commentMessage && c.commentMessage.length) || 0
                    }}/300</span>
                    <span class="cancel-btn" type="primary" @click="() => {
                          c.showMessage = false;
                          c.commentMessage = '';
                        }
                        ">取消</span>
                    <span class="post-btn" :class="{
                      disabled: !(
                        c.commentMessage && c.commentMessage.length > 0
                      ),
                    }" type="primary" @click="callbackComment(c, ci, null, null)">发布</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="callback" v-if="c.children.length > 0">
              <div class="comments-item" v-for="(v, i) in c.children" :key="i">
                <div class="comments-item-logo">
                  <img :src="v.avatarUrl && $store.state.assetsUrl + v.avatarUrl" />
                </div>
                <div class="comments-item-info">
                  <p class="comment-user">
                    <span>{{ v.nickName }}</span>
                  </p>
                  <p>
                    <span class="comment-back-tip">{{
                      v.cId && v.toUId ? "回复" : ""
                    }}</span>
                    <span v-if="v.cId && v.toUId" class="comment-user-back">{{ v.toNickName || "未知用户" }}：</span>
                    <span class="comments-item-content">{{ v.comment }}</span>
                  </p>
                  <p class="operate-btn">
                    <span class="operate-time">
                      <i class="el-icon-time"></i>{{ parseTime(v.createTime) }}
                    </span>
                    <span class="operate-btn-back" @click="callback(c, ci, v, i)"><i
                        class="el-icon-chat-dot-round"></i>回复</span>
                    <span class="operate-btn-delete" @click="deleteComment(v, ci, i)"
                      v-if="userInfo && v.uId == userInfo.id"><i class="el-icon-delete"></i>删除</span>
                  </p>
                  <div class="message" v-show="v.showMessage" v-if="userInfo">
                    <div class="message-input">
                      <el-input :ref="`message_${ci}_${i}`" v-model="v.commentMessage" type="textarea" placeholder="回复"
                        :rows="5" :maxlength="300"></el-input>
                      <p class="message-btns">
                        <span class="post-limit">{{
                          (v.commentMessage && v.commentMessage.length) || 0
                        }}/300</span>
                        <span class="cancel-btn" type="primary" @click="() => {
                              v.showMessage = false;
                              v.commentMessage = '';
                            }
                            ">取消</span>
                        <span class="post-btn" :class="{
                          disabled: !(
                            v.commentMessage && v.commentMessage.length > 0
                          ),
                        }" type="primary" @click="callbackComment(c, ci, v, i)">发布</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="showCallBackModal" title="填写回复">
      <el-input ref="callBackInput" v-model="callBackContent" type="textarea" :placeholder="'回复：' +
        ((callBackInfo.subComment && callBackInfo.subComment.nickName) ||
          (callBackInfo.comment && callBackInfo.comment.nickName))
        " :rows="5" :maxlength="300"></el-input>

      <div slot="footer">
        <el-button size="small" @click="cancelCallBackComment">取消</el-button>
        <el-button size="small" type="primary" @click="callbackComment">回复</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { parseTime, debounce } from "@/utils/index.js";
import { setOptions } from "../../utils";
import Collect from "../../assets/imgs/collect.png";
import CollectActive from "../../assets/imgs/collect_active.png";
import Download from "../../assets/imgs/download.png";
import { CommentFormData } from "../../assets/data/formbuilder";
export default {
  name: "ResourceDetail",
  data() {
    return {
      isDisabled: false,
      Collect,
      CollectActive,
      Download,
      purchaseCount: 0,
      previewImgListCounter: 0,
      previewImgSrc: "",
      previewImgIndex: null,
      detailData: {
        id: this.$route.query.id,
        comments: [],
        downloading: null,
      },
      placeholer: "",
      commentInfo: new CommentFormData({ rId: this.$route.query.id }).build(),

      showCallBackModal: false,
      callBackInfo: {},
      callBackContent: "",
    };
  },
  computed: {
    previewListStyle() {
      return {
        left: -this.previewImgListCounter.value * 110 + "px",
      };
    },
    resourceTypeList() {
      return setOptions("resource_type_list");
    },
    categoryList() {
      return setOptions("category_list");
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    baseUrl() {
      return this.$store.state.baseUrl;
    },
  },
  created() {
    this.getDetailData();
  },
  methods: {
    parseTime,
    scrollLeft() {
      this.previewImgListCounter > 0
        ? this.previewImgListCounter--
        : this.previewImgListCounter;
    },
    scrollRight() {
      this.previewImgListCounter >= this.detailData.posters.length - 5
        ? this.previewImgListCounter
        : this.previewImgListCounter++;
    },
    previewImg(src, index) {
      this.previewImgSrc = src;
      this.previewImgIndex = index;
    },
    getDetailData() {
      this.$get("/resources/detail", {
        id: this.detailData.id,
      }).then((res) => {
        if (res.code != 200) {
          this.isDisabled = true;
          return this.$message.error(res.message);
        }

        this.detailData = Object.assign(
          {
            downloading: null,
          },
          res.data,
          {
            description: res.data.description && res.data.description.split("\n").map(v => `<p>${v}<p>`).join("")
          },
        );

        this.detailData.categoryName = this.categoryList.find(
          (v) => v.value == this.detailData.category
        ).label;
        this.detailData.resourceTypeName = this.resourceTypeList.find(
          (v) => v.value == this.detailData.resourceType
        ).label;
      });
    },
    // 下载图片
    download: debounce(
      function () {
        if (this.isDisabled) return;
        if (!this.userInfo)
          return this.$store.dispatch("toggleUserLoginModal", true);

        this.detailData.downloading = null;
        this.$post("/resources/download", {
          id: this.detailData.id,
        }).then((res) => {
          if (res.code != 200) return this.$message.error(res.message);

          let filePath =
            this.$store.state.assetsUrl + (res.data.filePath || item.filePath);
          let fileName = res.data.resourceName || item.resourceName;

          fetch(filePath)
            .then((response) => response)
            .then(async (response) => {
              this.$message.success("开始为您下载，请不要关闭当前页面");
              const reader = response.body.getReader();
              // 获取总长度
              const contentLength = +response.headers.get("Content-Length");

              // 读取数据
              let receivedLength = 0; // 当前接收的字节数
              let chunks = []; // 接收到的二进制块的数组

              while (true) {
                const { done, value } = await reader.read();

                if (done) break;

                chunks.push(value);
                receivedLength += value.length;

                this.detailData.downloading =
                  ((receivedLength / contentLength) * 100).toFixed(0) - 0;
              }

              let blob = new Blob(chunks);

              let a = document.createElement("a");
              a.href = URL.createObjectURL(blob);
              a.download =
                fileName + filePath.substring(filePath.lastIndexOf("."));
              a.dispatchEvent(new MouseEvent("click"));
              a.remove();
              this.$message.success("下载成功");
              setTimeout(() => {
                this.detailData.downloading = null;
              }, 1000);
            })
            .catch((err) => {
              console.log(err);
              this.$message.error("资源获取失败，无法为您下载");
            });
        });
      },
      1000,
      true
    ),
    // 收藏/取消收藏
    collect: debounce(
      function () {
        if (this.isDisabled) return;
        if (!this.userInfo)
          return this.$store.dispatch("toggleUserLoginModal", true);
        this.$post("/resources/toggleCollect", {
          id: this.detailData.id,
        }).then((res) => {
          if (res.code != 200) return this.$message.error(res.message);

          this.detailData.isCollect = res.data;
          this.$message.success(
            res.data == 1 ? "感谢您的收藏，(^.^)" : "我会更加努力的，(ಥ﹏ಥ)"
          );
        });
      },
      300,
      true
    ),

    // ————————————————————————
    callback(comment, commentIndex, subComment, subCommentIndex) {
      if (!this.userInfo)
        return this.$store.dispatch("toggleUserLoginModal", true);
      this.showCallBackModal = true;
      this.callBackInfo = {
        comment,
        commentIndex,
        subComment,
        subCommentIndex,
      };
      this.$nextTick(() => {
        this.$refs.callBackInput.focus();
      });
    },
    // 重置回复表单
    cancelCallBackComment() {
      this.showCallBackModal = false;
      this.callBackContent = "";
      this.callBackInfo = {};
    },
    callbackComment() {
      let { comment, commentIndex, subComment, subCommentIndex } =
        this.callBackInfo;
      if (!this.userInfo)
        return this.$store.dispatch("toggleUserLoginModal", true);
      this.commentSubmit({
        rId: this.detailData.id,
        cId: comment.id,
        toUserId: subComment && subComment.uId,
        comment: this.callBackContent,
      }).then((res) => {
        let tempComment = {
          id: res.data,
          rId: this.detailData.id,
          cId: comment.id,
          comment: this.callBackContent,
          createTime: new Date(),
          uId: this.userInfo.id,
          nickName: this.userInfo.nickName,
          avatarUrl: this.userInfo.avatarUrl,
          toUId: subComment && subComment.uId,
          toNickName: (subComment && subComment.nickName) || comment.nickName,
          toAvatarUrl:
            (subComment && subComment.avatarUrl) || comment.avatarUrl,
          commentMessage: "",
          showMessage: false,
        };

        this.detailData.comments[commentIndex].children.push(tempComment);

        // 重置表单和弹窗
        comment && (comment.commentMessage = "");
        comment && (comment.showMessage = false);
        subComment && (subComment.commentMessage = "");
        subComment && (subComment.showMessage = false);
        this.cancelCallBackComment();
      });
    },
    postComment() {
      if (!this.userInfo)
        return this.$store.dispatch("toggleUserLoginModal", true);
      this.commentSubmit({
        ...this.commentInfo,
      }).then((res) => {
        let tempComment = {
          id: res.data,
          rId: this.detailData.id,
          cId: null,
          comment: this.commentInfo.comment,
          createTime: new Date(),
          uId: this.userInfo.id,
          nickName: this.userInfo.nickName,
          avatarUrl: this.userInfo.avatarUrl,
          toUId: null,
          toNickName: null,
          toAvatarUrl: null,
          commentMessage: "",
          showMessage: false,
          children: [],
        };
        this.detailData.comments.push(tempComment);
        this.commentInfo = new CommentFormData({
          rId: this.$route.query.id,
        }).build();
      });
    },
    commentSubmit(params) {
      return new Promise((resolve, reject) => {
        if (params.comment.trim().length == 0)
          return this.$message.warning("请留下您的评论吧");
        this.$post("/resources/postComment", {
          ...params,
        }).then((res) => {
          if (res.code != 200) return this.$message.error(res.message);

          this.$message.success("发送成功");
          resolve(res);
        });
      });
    },
    deleteComment(item, indexLevel1, indexLevel2) {
      if (!this.userInfo)
        return this.$store.dispatch("toggleUserLoginModal", true);
      this.$confirm("是否确定删除当前内容", "提示", {
        type: "warning",
      }).then(() => {
        this.$post("/resources/deleteComment", {
          id: item.id,
        }).then((res) => {
          if (res.code != 200) {
            return this.$message.error(res.message);
          }
          this.$message.success(res.message);
          if (typeof indexLevel2 == "number" && indexLevel2 > -1) {
            this.detailData.comments[indexLevel1].children.splice(
              indexLevel2,
              1
            );
          } else {
            this.detailData.comments.splice(indexLevel1, 1);
          }
        });
      });
    },
    goLogin() {
      return this.$store.dispatch("toggleUserLoginModal", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-body {
  margin: 50px auto 0;
  max-width: 1000px;
  border-radius: 8px;
  padding: 0 20px;

  .basic {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    .poster {
      width: 600px;
      background-color: #232529;
      border-radius: 18px;
      margin-bottom: 50px;

      .poster-preview {
        padding: 20px;
        width: 100%;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        img {
          display: block;
          width: auto;
          max-width: 100%;
        }
      }

      .poster-title {
        font-size: 18px;
        line-height: 1.3;
        font-weight: 600;
        margin-top: 20px;
        padding: 0 20px;
        color: #fff;
      }

      .poster-list {
        white-space: nowrap;
        max-width: 100%;
        margin-top: 10px;
        background-color: #232529;

        .poster-list-wrapper {
          width: 560px;
          overflow: hidden;
          display: inline-block;
          vertical-align: middle;
        }

        .poster-img-list {
          position: relative;
          transition: 0.3s;

          p {
            width: 100px;
            height: 100px;
            line-height: 90px;
            padding: 5px;
            margin-right: 10px;
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
            transition: 0.3s;
            text-align: center;
            border-radius: 4px;
            overflow: hidden;
            border: 1px solid #ccc;

            a {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
            }

            img {
              max-width: 100%;
              max-height: 100%;
            }

            &:hover {
              background-color: #f5f5f7;
            }

            &:nth-of-type(1) {
              margin-left: 10px;
            }

            &.active {
              border: 1px solid #000;
            }
          }
        }

        .left,
        .right {
          display: inline-block;
          vertical-align: middle;
          width: 20px;
          height: 100px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 14px 14px;
          background-color: #f5f5f7;
          margin: 0;
          cursor: pointer;
          border-radius: 4px;
          transition: 0.3s;

          &:hover {
            background-color: #dededf;
          }
        }

        .left {
          background-image: url("../../assets/imgs/left_icon.png");
        }

        .right {
          background-image: url("../../assets/imgs/right_icon.png");
        }
      }
    }

    .info {
      width: calc(100% - 640px);
      margin-left: 40px;
      padding: 0 20px 20px;
      border-radius: 18px;
      font-size: 16px;
      color: #666666;
      margin-bottom: 50px;
      background-color: #232529;

      .info-category,
      .info-publish {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #fff;
        font-size: 14px;
      }

      .info-label {
        display: inline-block;
        margin-right: 5px;
        color: #fff;
      }

      .info-tags {
        color: #fff;
        margin-top: 20px;

        .tag-item {
          display: inline-block;
          height: 24px;
          line-height: 24px;
          margin-right: 5px;
          margin-bottom: 5px;
          font-size: 12px;
          background-color: rgba(255, 255, 255, 0.1);
          color: #f5f5f7;
          padding: 0 10px;
          border-radius: 4px;
          cursor: pointer;
          transition: .3s;

          &:hover {
            background-color: rgba(0, 0, 0, 0.2);
          }
        }
      }

      .info-desc {
        font-size: 12px;
        background-color: rgba(255, 255, 255, 0.1);
        padding: 10px;
        border-radius: 4px;
        color: #f5f5f7;
        margin-top: 10px;

        .info-desc__content {
          text-indent: 2em;
          text-align: justify;
          word-break: break-all;
        }
      }

      .info-author {
        border-top: 1px solid #666666;
        padding-top: 15px;
        margin-top: 15px;
        font-size: 12px;

        .info-author-name {
          font-size: 14px;
          margin-bottom: 10px;

          .info-label {
            min-width: inherit;
            width: auto;
          }
        }

        .info-author-detail {
          line-height: 1.5em;
        }
      }

      .buttons {
        margin-top: 20px;

        .collect,
        .purchase {
          font-size: 14px;
          color: #fff;
          border-radius: 16px;
          text-align: center;
          padding: 0;
          height: 32px;
          vertical-align: middle;

          img {
            width: 32px;
            height: 32px;
            display: block;
            margin: 0 auto;
          }
        }

        .purchase {
          width: calc(100% - 42px);
          position: relative;

          .downloading-progress {
            width: 100%;
            height: 100%;
            position: absolute;
            left: -1px;
            top: -1px;
            z-index: 0;
          }

          span {
            position: absolute;
            left: -1px;
            top: -1px;
            display: inline-block;
            width: 100%;
            height: 100%;
            text-align: center;
            z-index: 1;
            height: 32px;
            line-height: 32px;
          }
        }

        .collect {
          margin-left: 10px;
          width: 32px;
          height: 32px;
          line-height: 32px;
          background-color: transparent;
          border: none;
          outline: none;
        }
      }
    }
  }

  .rich {

    .rich-title {
      background-color: #232529;
      color: #c5c7cc;
      border-bottom: 1px solid #000;
      padding: 10px 20px;
      border-radius: 8px 8px 0 0;
    }

    .rich-content {
      padding: 20px;
      border-radius: 4px;
      background-color: #232529;
      color: #fff;

      /deep/ {
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        span,
        a,
        li,
        ul,
        ol,
        article,
        main,
        nav,
        footer,
        header,
        aside,
        section,
        table,
        thead,
        tbody,
        tr,
        th,
        td,
        div {
          white-space: normal;
          word-break: break-all;
        }

        img,
        audio,
        video {
          max-width: 100%;
        }
      }
    }
  }
}

.tips-body {
  max-width: 1000px;
  margin: 30px auto 0;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;

  .tips-item {
    color: #666;
    font-size: 12px;
    text-indent: 2em;
    line-height: 1.5em;
  }
}

.message-body {
  max-width: 1000px;
  margin: 30px auto 0;
  border-radius: 8px;
  padding: 20px;

  .message-title {
    background-color: #232529;
    color: #c5c7cc;
    border-bottom: 1px solid #000;
    padding: 10px 20px;
    border-radius: 8px 8px 0 0;

    &.title-for-comments {
      margin-top: 20px;
    }
  }

  .message {
    padding-bottom: 60px;
    padding-top: 20px;
    border-radius: 4px;
    background-color: #232529;

    .message-input {
      position: relative;
      width: calc(100% - 40px);
      margin: 0 auto;

      /deep/.el-textarea__inner {
        border-radius: 4px 4px 0 0;
        outline: none;
        background-color: transparent;
        height: 150px;
        border-color: #999;
        color: #fff;
      }
    }

    .message-btns {
      position: absolute;
      width: 100%;
      height: 40px;
      line-height: 39px;
      text-align: right;
      border: 1px solid #999;
      border-top: none;

      span {
        display: inline-block;

        &.post-limit {
          padding: 0 10px;
          margin-right: 20px;
          color: #666;
          font-size: 14px;
        }

        &.cancel-btn {
          padding: 0 20px;
          color: #fff;
          font-size: 14px;
          background-color: #c5c7c5;
          cursor: pointer;
        }

        &.post-btn {
          cursor: pointer;
          padding: 0 20px;
          color: #fff;
          background-color: #000;

          &.disabled {
            opacity: 0.4 !important;
            cursor: not-allowed;
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    &.noLogin {
      padding-bottom: 20px;
      text-align: center;
      color: #fff;

      .login-tips {
        color: #5fc7bc;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .comments {
    background-color: #232529;
    padding: 0 20px 20px;

    .comment-btn {
      width: 150px;
    }

    .comment-user,
    .comment-user-back {
      color: #5fc7bc;
      font-weight: bold;
    }

    .comment-back-tip {
      color: #c5c7cc;
    }

    .comments-item {
      // display: flex;
      // justify-content: flex-start;
      // align-items: flex-start;
      padding: 20px 0 0;

      .comments-item-info {
        width: calc(100% - 40px);
        display: inline-block;
        padding-left: 5px;
        font-size: 14px;

        &>div {
          margin-top: 5px;
        }

        &>p:nth-of-type(2) {
          margin-top: 5px;
        }

        .comments-item-content {
          color: #c5c7cc;
        }

        &:hover {
          .operate-btn {
            .operate-btn-delete {
              display: inline-block;
            }
          }
        }
      }

      .comments-item-logo {
        display: inline-block;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        margin-right: 8px;
        overflow: hidden;
        vertical-align: top;
      }

      .comments-item-logo img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      .comments-icon {
        font-size: 24px;
        width: 24px;
        height: 64px;
        margin-right: 20px;
        line-height: 64px;
      }

      .operate-btn {
        margin-top: 5px;
        text-align: left;
        color: #999;
        padding: 5px 0;
        font-size: 12px;

        span {
          cursor: pointer;
          margin-right: 20px;

          i {
            margin-right: 5px;
          }

          &.operate-btn-back {
            &:hover {
              color: #5fc7bc;
            }
          }

          &.operate-btn-delete {
            display: none;

            &:hover {
              color: #fa6400;
            }
          }
        }
      }
    }
  }

  .callback {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    width: calc(100% - 40px);
    margin-left: 40px;
    padding-bottom: 10px;

    .comments-item {
      padding: 10px 10px 0 10px;
      border: none;
    }

    .message {
      margin-top: 10px;

      .message-input {
        /deep/.el-textarea__inner {
          height: 100px;
          background-color: transparent;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .detail-body {
    margin-top: 20px !important;
  }

  .poster {
    margin: 0 auto;
    width: 100% !important;
  }

  .info {
    padding: 20px;
    width: 100% !important;
    margin-left: 0 !important;
  }
}
</style>
